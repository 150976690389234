<nd-back-button></nd-back-button>
<div class="card ">
    <clr-progress-bar [class.invisible]="!isLoading" clrValue="75" clrLoop></clr-progress-bar>
    <div class="p-3 pt-1">
        <h3> <cds-icon shape="cog" size="md" style="--color: var(--ndp-brand-color)"></cds-icon>
            Auto Lifecycle Configuration</h3>
    </div>
    <div class="p-3">
        <clr-select-container>
            <label for="testingToAcceptance">Move from testing to acceptance in:</label>
            <select clrSelect name="testingToAcceptance" [disabled]="isLoading"
                [(ngModel)]="configuration.flowToProductionInHours">
                <option value="null">Never</option>
                <option value="1">1 hour</option>
                <option value="2">2 hours</option>
                <option value="4">4 hours</option>
                <option value="8">8 hours</option>
                <option value="24">1 day</option>
                <option value="48">2 days</option>
                <option value="96">4 days</option>
                <option value="168">1 week</option>
                <option value="336">2 weeks</option>
            </select>
        </clr-select-container>
        <p class="mt-1 mb-3">This configures whether and when the system should automatically migrate testing versions
            to acceptance</p>
        <clr-select-container>
            <label for="acceptanceToProduction">Move from acceptance to production in:</label>
            <select clrSelect name="testingToAcceptance" [disabled]="isLoading"
                [(ngModel)]="configuration.flowToAcceptanceInHours">
                <option value="null">Never</option>
                <option value="1">1 hour</option>
                <option value="2">2 hours</option>
                <option value="4">4 hours</option>
                <option value="8">8 hours</option>
                <option value="24">1 day</option>
                <option value="48">2 days</option>
                <option value="96">4 days</option>
                <option value="168">1 week</option>
                <option value="336">2 weeks</option>
            </select>
        </clr-select-container>
        <p class="mt-1 mb-3">This configures whether and when the system should automatically migrate acceptance
            versions to production</p>
    </div>
    <div class="actions mt-3 pb-3">
        <button [disabled]="isLoading" (click)="saveChanges()" type="button" class="btn btn-icon btn-success btn-md"
            #tooltip="matTooltip" matTooltip="Save changes to configuration" [matTooltipPosition]="'above'"
            matTooltipHideDelay="300">
            <clr-icon shape="check"></clr-icon>
            Save Changes
        </button>
    </div>
</div>